export const timeJson1 = [
  [
    {
      time: "08:00-09:30",
      remain: 18,
      type: "兰州生物",
    },
    {
      time: "09:30-11:00",
      remain: 0,
      type: "兰州生物",
    },
    {
      time: "11:00-12:00",
      remain: 12,
      type: "兰州生物",
    },
    {
      time: "13:00-14:30",
      remain: 5,
      type: "北京科兴中维",
    },
    {
      time: "14:30-16:00",
      remain: 12,
      type: "北京科兴中维",
    },
    {
      time: "16:00-17:30",
      remain: 0,
      type: "北京科兴中维",
    },
  ],
  [
    {
      time: "08:00-09:30",
      remain: 23,
      type: "兰州生物",
    },
    {
      time: "09:30-11:00",
      remain: 12,
      type: "兰州生物",
    },
    {
      time: "11:00-12:00",
      remain: 5,
      type: "兰州生物",
    },
    {
      time: "13:00-14:30",
      remain: 0,
      type: "北京科兴中维",
    },
    {
      time: "14:30-16:00",
      remain: 0,
      type: "北京科兴中维",
    },
    {
      time: "16:00-17:30",
      remain: 0,
      type: "北京科兴中维",
    },
  ],
  [
    {
      time: "08:00-09:30",
      remain: 24,
      type: "兰州生物",
    },
    {
      time: "09:30-11:00",
      remain: 25,
      type: "兰州生物",
    },
    {
      time: "11:00-12:00",
      remain: 17,
      type: "兰州生物",
    },
    {
      time: "13:00-14:30",
      remain: 8,
      type: "兰州生物",
    },
    {
      time: "14:30-16:00",
      remain: 11,
      type: "兰州生物",
    },
    {
      time: "16:00-17:30",
      remain: 0,
      type: "北京科兴中维",
    },
  ],
  [
    {
      time: "08:00-09:30",
      remain: 7,
      type: "北京科兴中维",
    },
    {
      time: "09:30-11:00",
      remain: 0,
      type: "北京科兴中维",
    },
    {
      time: "11:00-12:00",
      remain: 0,
      type: "北京科兴中维",
    },
    {
      time: "13:00-14:30",
      remain: 0,
      type: "北京科兴中维",
    },
    {
      time: "14:30-16:00",
      remain: 0,
      type: "北京科兴中维",
    },
    {
      time: "16:00-17:30",
      remain: 3,
      type: "北京科兴中维",
    },
  ],
  [
    {
      time: "08:00-09:30",
      remain: 32,
      type: "兰州生物",
    },
    {
      time: "09:30-11:00",
      remain: 28,
      type: "兰州生物",
    },
    {
      time: "11:00-12:00",
      remain: 33,
      type: "兰州生物",
    },
    {
      time: "13:00-14:30",
      remain: 0,
      type: "北京科兴中维",
    },
    {
      time: "14:30-16:00",
      remain: 0,
      type: "北京科兴中维",
    },
    {
      time: "16:00-17:30",
      remain: 7,
      type: "北京科兴中维",
    },
  ],
  [
    {
      time: "08:00-09:30",
      remain: 7,
      type: "北京科兴中维",
    },
    {
      time: "09:30-11:00",
      remain: 0,
      type: "北京科兴中维",
    },
    {
      time: "11:00-12:00",
      remain: 0,
      type: "北京科兴中维",
    },
    {
      time: "13:00-14:30",
      remain: 0,
      type: "北京科兴中维",
    },
    {
      time: "14:30-16:00",
      remain: 0,
      type: "北京科兴中维",
    },
    {
      time: "16:00-17:30",
      remain: 3,
      type: "北京科兴中维",
    },
  ],
];

export const dayJson = [
  [
    {
      time: "08:00-09:30",
      resources: 35,
    },
    {
      time: "09:30-11:00",
      resources: 12,
    },
    {
      time: "11:00-12:00",
      resources: 5,
    },
    {
      time: "13:00-14:30",
      resources: 0,
    },
    {
      time: "14:30-16:00",
      resources: 2,
    },
    {
      time: "16:00-17:30",
      resources: 0,
    },
  ],
  [
    {
      time: "08:00-09:30",
      resources: 67,
    },
    {
      time: "09:30-11:00",
      resources: 32,
    },
    {
      time: "11:00-12:00",
      resources: 15,
    },
    {
      time: "13:00-14:30",
      resources: 11,
    },
    {
      time: "14:30-16:00",
      resources: 5,
    },
    {
      time: "16:00-17:30",
      resources: 3,
    },
  ],
  [
    {
      time: "08:00-09:30",
      resources: 90,
    },
    {
      time: "09:30-11:00",
      resources: 66,
    },
    {
      time: "11:00-12:00",
      resources: 45,
    },
    {
      time: "13:00-14:30",
      resources: 32,
    },
    {
      time: "14:30-16:00",
      resources: 18,
    },
    {
      time: "16:00-17:30",
      resources: 19,
    },
  ],
  [
    {
      time: "08:00-09:30",
      resources: 90,
    },
    {
      time: "09:30-11:00",
      resources: 90,
    },
    {
      time: "11:00-12:00",
      resources: 90,
    },
    {
      time: "13:00-14:30",
      resources: 82,
    },
    {
      time: "14:30-16:00",
      resources: 65,
    },
    {
      time: "16:00-17:30",
      resources: 32,
    },
  ],
  [
    {
      time: "08:00-09:30",
      resources: 90,
    },
    {
      time: "09:30-11:00",
      resources: 88,
    },
    {
      time: "11:00-12:00",
      resources: 79,
    },
    {
      time: "13:00-14:30",
      resources: 83,
    },
    {
      time: "14:30-16:00",
      resources: 55,
    },
    {
      time: "16:00-17:30",
      resources: 69,
    },
  ],
  [
    {
      time: "08:00-09:30",
      resources: 75,
    },
    {
      time: "09:30-11:00",
      resources: 68,
    },
    {
      time: "11:00-12:00",
      resources: 72,
    },
    {
      time: "13:00-14:30",
      resources: 66,
    },
    {
      time: "14:30-16:00",
      resources: 57,
    },
    {
      time: "16:00-17:30",
      resources: 37,
    },
  ],
];
